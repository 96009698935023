<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-button
        class="md-raised md-icon-button mg-left-0"
        :to="{ name: 'Article' }"
      >
        <font-awesome-icon icon="arrow-left" />
      </md-button>
    </div>
    <div class="md-layout-item md-size-70 md-medium-size-100">
      <span class="md-title"> {{ $t("csv_example") }} </span>

      <md-field>
        <md-textarea disabled v-model="example"></md-textarea>
      </md-field>

      <md-switch v-model="hasHeader">{{ $t("has_header") }}</md-switch>

      <md-field>
        <label>{{ $t("separator") }}</label>
        <md-select v-model="separator">
          <md-option value=";">;</md-option>
          <md-option value=",">,</md-option>
        </md-select>
      </md-field>

      <md-field>
        <label>CSV</label>
        <md-file accept=".csv" @change="manageFile" />
      </md-field>

      <md-button
        class="md-raised mg-left-0"
        :disabled="submiting"
        @click="submit"
      >
        {{ $t("submit") }}
      </md-button>
    </div>
  </div>
</template>

<script>
import { Article } from "../../common/api/api.service";
import { TOOLBAR_TITLE } from "../../store/actions.types";
export default {
  name: "ArticleImport",
  data() {
    return {
      submiting: false,
      example:
        "REFERENCE;DESCRIPTION;VOLUMEN;ANCHO;LARGO;ALTO;PESO;TIPO(CYLINDER/GRID)\n101438;TEST ARTICLE;80000;3000;4000;1500;600;GRID",
      file: null,
      hasHeader: true,
      separator: ";",
    };
  },
  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "article_import");
  },

  methods: {
    manageFile(ev) {
      let files = ev.target.files;
      this.file = files[0];
    },
    submit() {
      this.submiting = true;

      let formData = new FormData();
      formData.append("file", this.file);

      Article.import(formData, this.hasHeader, this.separator)
        .then((res) => {
          this.$toast.success(res.data);
          this.$router.push({ name: "Article" });
        })
        .catch(() => {
          this.$toast.error("Error");
        })
        .finally(() => {
          this.submiting = false;
        });
    },
  },
};
</script>
